import styled from "styled-components";

export const ArticlesContainer = styled.div`
    width: 60%;
    margin: 0 auto;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1250px) {
        width: 80%;
    }
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ArticlesTitle = styled.h2`
    font-weight: 600;
`;

export const AllArticles = styled.div`
    margin: 30px 0;
    display: flex;
    column-gap: 20px;
    row-gap: 40px;
    flex-wrap: wrap;
`;