import styled from "styled-components";

export const IntroContainer = styled.section`
    display: flex;
    justify-content: center;
    width: 60vw;
    padding: 50px 0;
    align-self: center;
    align-items: center;

    @media (max-width: 768px) {
        text-align: center;
        width: 90vw;
    }
`;

export const IntroTitle = styled.h2`
    color: #333333;
    font-weight: 600;
    margin-bottom: 40px;
    
    br {
        display: none;
    }

    @media (max-width: 768px) {
        br {
            display: block;
        }
    }
    
    .intro-text {
        color: #214697;
    }
`;

export const IntroDescription = styled.div`
    font-weight: 600;
    margin-right: 40px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        margin: 0;
    }
`;

export const IntroSubtitle = styled.p`
    @media (max-width: 768px) {
        color: #214697;
    }
    font-weight: 500;
`;

export const StartSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 75px;

    @media (max-width: 768px) {
        align-self: center;
    }
`;

export const IntroButton = styled.button`
    background: #FA7922;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 1.5rem;
    font-weight: 600;
`;

export const IntroButtonSubText = styled.p`
    margin-top: 8px;
    font-size: 0.9rem;
    text-align: center;
    
    span {
        color: #214697;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        font-size: 1.1rem
    }
`;

export const IntroImage = styled.img`
    width: 300px;
    @media (max-width: 768px) {
        display: none;
    }
`;