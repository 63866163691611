export const SITE_LOGO = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fsite-logo.png?alt=media&token=cd929e1e-c798-4c06-b20f-670429e1b712";
export const USER = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fuser.png?alt=media&token=525f0f74-83a5-4646-afd2-ed6c6f9b3588";
export const NL_FLAG = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fnl-flag.png?alt=media&token=d10d1817-8198-40a8-b2d1-f8c33363cf43";
export const EN_FLAG = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fen-flag.png?alt=media&token=9a2386a2-4d3a-44d6-95ff-1cf9c93148ab";
export const MENU = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fhamburger.png?alt=media&token=be13372d-548e-406e-b41e-1e65a86f348d";
export const LOG_OUT = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Flogout.png?alt=media&token=183b0295-9440-43fc-a756-432ae68e1645";
export const DELETE = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fdelete.png?alt=media&token=e49d61de-43c1-4d92-b1a4-8aa4dcdaabbc";
export const EDIT = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fedit.png?alt=media&token=e651b632-cd7d-420b-8a7b-089b44e9dece";
export const OPEN_LINK = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fshare.png?alt=media&token=df8c3339-700a-4d6c-9997-056e1e46ca6b";
export const INTRO_IMAGE = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fwoman-looking-at-phone.svg?alt=media&token=a8e95ceb-f580-4edd-827c-cdae66fa3d79";
export const CHECK = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fcheck.png?alt=media&token=e1bd9d96-1bf8-4149-8744-014e5e2b03b3";
export const PRICES_IMAGE = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fpeople-talking.png?alt=media&token=6777f7e0-3756-4d36-a0cc-5c71a6f62b06";
export const REASON_ONE = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fhomepage-illustration-two.png?alt=media&token=e8760b30-285f-44e8-af20-9d716383ad80";
export const REASON_TWO = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fhomepage-illustration-one.png?alt=media&token=bf1f8749-931c-4161-a3a5-c7b0be9da032";
export const REASON_THREE = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fhomepage-illustration-three.png?alt=media&token=b1153141-5d46-4e43-ae77-f606b5e9ab49";
export const SEARCH_HOUSE = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fhouse.png?alt=media&token=d61e1dfe-04a4-4d97-a44f-6e3479fc4cab";
export const INSTAGRAM = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Finstagram.png?alt=media&token=80abf2ba-89fd-4ebb-981b-49ec3f0ba035";
export const TIKTOK = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Ftiktok.png?alt=media&token=85af363d-310d-4604-a48a-d764c40987fb";

export const RADIUS = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fradius.png?alt=media&token=8fa0be40-6716-4748-8f6a-4a3e30309210";
export const PRICE_TAG = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fprice-tag.png?alt=media&token=14bd9726-6f3b-4557-9352-764111225e0a";
export const RULER = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fruler.png?alt=media&token=74e459b9-ad33-43a4-a0ac-f878be61ab65";
export const BED = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fbed.png?alt=media&token=dc12e9cd-206f-42a9-a1c0-72c46bc34887";
export const DROPDOWN_ARROW = "https://firebasestorage.googleapis.com/v0/b/the-rent-friend.appspot.com/o/images%2Fdropdown-arrow.png?alt=media&token=3b9dcdfe-19e4-42b7-8a7d-d5d066004f4a";