import styled from "styled-components";

export const SearchesTable = styled.table`
    overflow: scroll;
    font-size: 1.2rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    
    th, td {
        text-align: left;
        padding: 10px 25px;
    }
    
    td {
        height: 75px;
    }
    
    thead {
        border-bottom: 1px solid #f2f2f2;
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    
    tbody {
        display: block;
        max-height: 250px;
        overflow-y: scroll;
        width: 100%;
    }
    
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        overflow: scroll;
    }
    
    tfoot {
        div {
            padding: 10px 25px;
            color: #1b72e8;
            font-size: 1.2rem;
        }
    }
`;

export const MatchImage = styled.img`
    height: 50px;
`;

export const RowActions = styled.td`
    display: flex;
`;

export const RowAction = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    margin: 0 2px 0 0;
    
    svg {
        width: 24px;
    }
    
    img {
        width: 20px;
    }
`;

export const EmptyTable = styled.div`
    display: flex;
    padding: 32px;
    justify-content: center;
`;

export const MatchUrl = styled.span`
    margin-right: 8px;
    font-size: 1rem;
`;