import {observer} from "mobx-react-lite";
import {
    ContactContainer,
    ContactForm,
    ContactFormContainer,
    ContactInfo,
    ContactInfoHeader,
    ContactInfoText, SubmitContactFormBtn
} from "./styles";
import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import CustomInput from "../../components/customInput";
import {AuthFormItem, FormRow} from "../authentication/styles";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {ContactClient} from "../../agent/contact";

function Contact() {
    const intl = useIntl();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const contactResponse = (type, msg) => {
        toast[type](msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (name === "" || email === "" || phone === "" || message === "") {
            contactResponse("error", intl.formatMessage({id: "fill-all-fields-search"}));
        } else {
            const body = {
                name: name,
                email: email,
                phone: phone,
                message: message,
            };

            ContactClient.sendMessage(body).then(success => {
                contactResponse(
                    success ? "success" : "error",
                    intl.formatMessage({id: success ? "contact-form-response" : "contact-form-error"})
                )
            });
        }
    };

    return (
      <ContactContainer>
          <ContactInfo>
              <ContactInfoHeader><FormattedMessage id="contact-info-header"/></ContactInfoHeader>
              <ContactInfoText>
                  <FormattedMessage
                      id="contact-info-description"
                      values={{
                          link: <Link to="/faq">(FAQ)</Link>,
                          break: <><br/><br/></>
                      }}
                  />
              </ContactInfoText>
              <p><b><FormattedMessage id="contact-info-email"/></b>info@therentfriend.nl</p>
              <p><b><FormattedMessage id="contact-info-kvk"/></b>91239249</p>
              <p><b><FormattedMessage id="contact-info-vat"/></b>NL865591416B01</p>
          </ContactInfo>

          <ContactFormContainer>
            <ContactForm onSubmit={onSubmit}>
                <FormRow>
                    <AuthFormItem>
                        <CustomInput
                            placeholder={intl.formatMessage({id: "contact-form-name-placeholder"})}
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </AuthFormItem>
                    <AuthFormItem>
                        <CustomInput
                            placeholder={intl.formatMessage({id: "auth-email-placeholder"})}
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </AuthFormItem>
                </FormRow>
                <AuthFormItem>
                    <CustomInput
                        placeholder={intl.formatMessage({id: "contact-form-phone-nr-placeholder"})}
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </AuthFormItem>
                <AuthFormItem>
                    <CustomInput
                        placeholder={intl.formatMessage({id: "contact-form-message-placeholder"})}
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </AuthFormItem>

                <SubmitContactFormBtn type="submit">
                    <FormattedMessage id="contact-form-submit-btn" />
                </SubmitContactFormBtn>
            </ContactForm>
          </ContactFormContainer>
          <ToastContainer />
      </ContactContainer>
    );
}

export default observer(Contact);