import {observer} from "mobx-react-lite";
import {FormattedMessage} from "react-intl";
import {AuthFormTitle, AuthSubmitButton} from "../../pages/authentication/styles";
import {useEffect, useState} from "react";
import {Subscription} from "../../agent/subscription";
import MSubcriptionCard from "../subscriptionCard/mobileIndex";
import {FunnelPrices} from "./styles";
import {userStore} from "../../stores";

function FunnelPayment() {
    const [selectedSub, setSelectedSub] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);

    const handleSelectedSubChange = (value) => {
        setSelectedSub(value);
    };

    const onFunnelSubmit = (e) => {
        e.preventDefault();
        const registerBody = JSON.parse(localStorage.getItem("credentials"));
        const searchBody = JSON.parse(localStorage.getItem("search"));

        if (registerBody && searchBody) {
            const body = {
                ...registerBody,
                search: searchBody,
                sku: selectedSub.sku
            };

            userStore.funnelRegister(body).then(success => {
               if (success) {
                   localStorage.delete("search");
                   localStorage.delete("credentials");
               }
            });
        }
    };

    useEffect( () => {
        Subscription.all().then(res => {
            setSubscriptions(res)
        });
    }, []);

    return (
        <>
            <AuthFormTitle>
                <FormattedMessage id="select-your-subscription"/>
            </AuthFormTitle>

            <FunnelPrices>
                {subscriptions.map(subscription => (
                    <MSubcriptionCard
                        key={`m-${subscription.sku}`}
                        value={subscription}
                        selectedSub={selectedSub}
                        handleSelectedSubChange={handleSelectedSubChange}
                    />
                ))}
            </FunnelPrices>

            <AuthSubmitButton type="button" disabled={!selectedSub} onClick={onFunnelSubmit}>
                <FormattedMessage id="auth-register" />
            </AuthSubmitButton>
        </>
    );
}

export default observer(FunnelPayment);