import {observer} from "mobx-react-lite";
import {
    ActivateBtn,
    MaxSearches,
    PerMonth, SubBody,
    SubCardHeader,
    SubDuration,
    SubInfo,
    SubPrice,
    SubscriptionCardContainer
} from "./styles";
import {FormattedMessage, useIntl} from "react-intl";

function SubscriptionCard({value, onSubClick}) {
    const intl = useIntl();
    const price = parseFloat(value["stripe_default_price"]).toFixed(2).toString();
    const splitPrice = price.split(".");
    const base = splitPrice[0];
    const decimals = splitPrice[1] ?? "";

    const formatSearchesText = () => {
        let queriesText = intl.formatMessage({id: "subscription-card-months"});
        const searchesAmount = value["min_months"];

        if (searchesAmount === 1) {
            queriesText = queriesText.replaceAll("s", "").replaceAll("en", "")
        }

        return `${searchesAmount} ${queriesText}`
    };

    return (
        <>
            <SubscriptionCardContainer>
                <SubCardHeader>
                    <SubDuration className="sub-duration">
                        {formatSearchesText()}
                    </SubDuration>
                </SubCardHeader>

                <SubBody>
                    <SubInfo>
                        <SubPrice className="sub-price">
                            <span className="currency">€</span>
                            {base}
                            <span className="price-decimals">{decimals}</span>
                        </SubPrice>
                        <PerMonth>
                            <FormattedMessage id="subscription-card-each-month"/>
                        </PerMonth>
                    </SubInfo>

                    <ActivateBtn onClick={() => onSubClick(value)}>
                        <FormattedMessage id="subscription-card-activate"/>
                    </ActivateBtn>
                </SubBody>
            </SubscriptionCardContainer>
        </>
    );
}

export default observer(SubscriptionCard);