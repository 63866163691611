import styled from "styled-components";
import {ActivateBtn} from "../subscriptionCard/styles";

export const AllSubscriptions = styled.div`
    display: flex;
    align-items: center;
    width: 60%;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
    }
`;

export const SubInsurance = styled.p`
    font-size: 1.2rem;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const StarMeaning = styled.p`
    font-size: 0.9rem;
    margin-top: 15px;

    @media (max-width: 768px) {
        font-size: 1.1rem;
    }
`;

export const MobileActivateBtn = styled(ActivateBtn)`
    display: none;
    background: #FA7922;
    color: white;
    padding: 10px 40px;
    font-weight: 600;
    
    @media (max-width: 768px) {
        display: block;
    }
`;