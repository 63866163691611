import {observer} from "mobx-react-lite";
import {StyledInput} from "./styles";

function CustomInput({onFocus, onBlur, placeholder, type, value, onChange}) {
    return (
        <StyledInput
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
    );
}

export default observer(CustomInput);