import styled from "styled-components";
import {MapContainer} from "react-leaflet";

export const StyledMapContainer = styled(MapContainer)`
    height: 400px;
    flex: 1;
    z-index: 9;

    @media (max-width: 768px) {
        flex: unset;
    }
`;