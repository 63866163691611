import {observer} from "mobx-react-lite";
import {QuestionAnswer, QuestionContainer, QuestionText} from "./styles";
import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";

function Question({number}) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    return (
        <QuestionContainer>
            <QuestionText onClick={() => setOpen(!open)} open={open}>
                <FormattedMessage id={`faq-${number}-question`} />
            </QuestionText>
            {open && (
                <QuestionAnswer>
                    <FormattedMessage
                        id={`faq-${number}-answer`}
                        values={{
                            clickHere: <a href="https://www.juridischloket.nl/wonen-en-buren/huurwoning/bemiddelingskosten/" target="_blank">{intl.formatMessage({id: "click-here"})}</a>
                        }}
                    />
                </QuestionAnswer>
            )}
        </QuestionContainer>
    );
}

export default observer(Question);