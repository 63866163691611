import styled from "styled-components";

export const FunnelAuthContainer = styled.div`
    padding: 30px;
    border: 1px solid #f2f2f2;
    border-radius: 15px;
    width: 500px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

export const FunnelAuthHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const FunnelAuthDropDownArrow = styled.img`
    height: 40px;
    width: 40px;
    margin-left: auto;
    display: none;
    //transform: rotate(180deg);

    @media (max-width: 768px) {
        display: block;
        transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
`;

export const FunnelAuthTitle = styled.h3`
    font-weight: 600;
`;

export const FunnelSearch = styled.div`
    width: 100%;
    background: #e6e6e6;
    padding: 20px;
    border-radius: 10px;
    margin-top: 35px;
    
    @media (max-width: 768px) {
        display: ${props => props.open ? "block" : "none"};
    }
`;

export const FunnelSearchLocation = styled.h4`
    font-weight: 600;
`;

export const FunnelSearchSpecs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 10px;
`;

export const FunnelSearchSpec = styled.div`
    border-radius: 5px;
    background: white;
    padding: 10px;
    display: flex;
    align-items: center;
    
    img {
        height: 25px;
        margin-right: 10px;
    }
`;