import styled from "styled-components";

export const PasswordRequirementsContainer = styled.div`
    font-size: 1.2rem;
    width: 100%;
    padding: 20px;
    bottom: calc(100% + 10px);
    background: white;
    position: absolute;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
`;

export const Requirements = styled.ul`
    margin-top: 10px;
`;

export const Requirement = styled.li`
    color: ${props => props.valid ? "green" : "black"};
`;