import {makeAutoObservable} from "mobx";
import {createBrowserHistory} from "history";

export class LocaleStore {
    value = "nl";
    history = createBrowserHistory();

    constructor() {
        makeAutoObservable(this);
    }

    setValue(val) {
        this.value = val;
    }
}