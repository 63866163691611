import {observer} from "mobx-react-lite";
import {
    IntroContainer,
    IntroDescription,
    IntroImage,
    IntroSubtitle,
    IntroTitle
} from "./styles";
import {INTRO_IMAGE} from "../../constants/images";
import {FormattedMessage, useIntl} from "react-intl";
import StartSearchDispleased from "../startSearchDispleased";
import StartSearchFunnel from "../startSearchFunnel";
import {userStore} from "../../stores";

function Intro() {
    const intl = useIntl();

    return (
        <IntroContainer>
            <IntroDescription>
                <IntroTitle>
                    <FormattedMessage
                        id="intro-title"
                        values={{
                            effortlessly: <span className="intro-text">
                                {intl.formatMessage({id: "intro-effortlessly"})}
                            </span>,
                            break: <><br></br></>
                        }}
                    />
                </IntroTitle>
                <IntroSubtitle>
                    <FormattedMessage id="intro-subtitle" />
                </IntroSubtitle>

                {userStore.authenticated && (
                    <StartSearchDispleased />
                )}
                {!userStore.authenticated && (
                    <StartSearchFunnel />
                )}

            </IntroDescription>
            <IntroImage src={INTRO_IMAGE} alt="woman-looking-at-phone" />
        </IntroContainer>
    );
}

export default observer(Intro);