import styled from "styled-components";

export const PricesHeader = styled.section`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 100px 0;
    
    img {
        width: 180px;
    }

    @media (max-width: 768px) {
        width: 90%;
        padding: 15px 0;
        
        img {
            display: none;
        }
    }
`;

export const PricesText = styled.div`
    margin-left: 50px;

    @media (max-width: 768px) {
        margin: 0;
        text-align: center;
    }
`;

export const PricesTitle = styled.h1`
    font-weight: 600;
    margin-bottom: 16px;
`;

export const PricesDescription = styled.p`
    font-weight: 400;
`;