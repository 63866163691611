import styled from "styled-components";
import {AuthForm, AuthFormContainer} from "../authentication/styles";
import {ActivateBtn} from "../../components/subscriptionCard/styles";

export const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100%;
    margin: auto;

    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        margin: 20px auto;
    }
`;

export const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 45%;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const ContactInfoHeader = styled.h2`
    font-weight: 600;
    color: #214697;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const ContactInfoText = styled.p`
    margin-bottom: 20px;
`;

export const ContactFormContainer = styled(AuthFormContainer)`
    width: 45%;
    margin-left: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    
    @media (max-width: 768px) {
        width: 100%;
        margin: 50px 0;
    }
`;

export const ContactForm = styled(AuthForm)``;

export const SubmitContactFormBtn = styled(ActivateBtn)`
    padding: 8px 15px;
`;