import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {Subscription} from "../../agent/subscription";
import {AllSubscriptions, MobileActivateBtn, StarMeaning, SubInsurance} from "./styles";
import SubscriptionCard from "../subscriptionCard";
import {FormattedMessage, useIntl} from "react-intl";
import {userStore} from "../../stores";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import MSubcriptionCard from "../subscriptionCard/mobileIndex";

function Subscriptions() {
    const intl = useIntl();
    const navigate = useNavigate();
    const [selectedSub, setSelectedSub] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);

    const handleSelectedSubChange = (value) => {
        setSelectedSub(value);
    };

    const waitForPayment = async (sessionId) => {
        for (let i = 0; i < 30; i++) {
            const status = await userStore.getPaymentStatus(sessionId);

            if (status === "completed") return true;
            await sleep();
        }
        return false;
    };

    const sleep = () => new Promise(resolve => setTimeout(resolve, 2000));

    const showToastMessage = (type, msg) => {
        toast[type](msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    };

    const showPaymentFailed = () => {
        showToastMessage("error", intl.formatMessage({id: "payment-fail-msg"}))
    };

    const showPaymentSuccess = () => {
        showToastMessage("success", intl.formatMessage({id: "payment-success-msg"}))
        navigate("/profile");
    };

    const onSubClick = () => {
        if (selectedSub) {
            onActivateClick(selectedSub);
        }
    };

    const onActivateClick = (subscription) => {
        if (!userStore.authenticated) navigate("/register");
        else {
            userStore.createPaymentSession(subscription["sku"]).then(url => {
                if (url) {
                    const path = new URL(url).pathname;
                    const sessionId = path.split('/').pop();

                    window.open(url, "_blank");
                    waitForPayment(sessionId).then(success => {
                        if (success) showPaymentSuccess();
                        else showPaymentFailed();
                    });
                }
            });
        }
    };

    useEffect( () => {
        Subscription.all().then(res => {
            setSubscriptions(res)
        });
    }, []);

    return (
        <>
            <AllSubscriptions>
                {subscriptions.map((subscription, i) => (
                    <>
                        <SubscriptionCard
                            key={subscription.sku}
                            value={subscription}
                            onSubClick={onActivateClick}
                        />
                        <MSubcriptionCard
                            key={`m-${subscription.sku}`}
                            value={subscription}
                            selectedSub={selectedSub}
                            handleSelectedSubChange={handleSelectedSubChange}
                        />
                    </>
                ))}
            </AllSubscriptions>

            <MobileActivateBtn onClick={onSubClick}>
                <FormattedMessage id="subscription-card-activate"/>
            </MobileActivateBtn>

            <SubInsurance>
                <FormattedMessage id="subscription-displeased" />
            </SubInsurance>

            <StarMeaning>
                <FormattedMessage id="money-back-contact" />
            </StarMeaning>

            <ToastContainer/>
        </>
    );
}

export default observer(Subscriptions);