import styled from "styled-components";

export const ArticleContainer = styled.div`
    width: 60%;
    margin: 0 auto;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    
    h1 {
        margin-bottom: 20px;
    }
    
    .article-summary {
        margin-bottom: 20px;
        font-weight: 600;
    }

    @media (max-width: 1250px) {
        width: 100%;
    }
`;

export const ArticleContent = styled.div`
    margin-top: 40px;
    
    p {
        margin-bottom: 20px;
    }
    
    h3 {
        margin: 40px 0 20px 0;
    }
    
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        margin: 50px 0;
        border-collapse: collapse;
        
        th, td {
            border: 1px solid lightgray;
            padding: 10px 25px;
        }
    }
`;