import {WORKER_URL} from "../constants/api";

export const ArticleClient = {
    all: async () => {
        const res = await fetch(`${WORKER_URL}/all-articles`, {
            method: 'GET',
        });
        return await res.json();
    },
    get: async (articleKey) => {
        const body = {articleKey};
        const res = await fetch(`${WORKER_URL}/article-details`, {
           method: "POST",
           body: JSON.stringify(body)
        });

        return await res.json();
    }
}