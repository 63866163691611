import {BASE_URL, WORKER_URL} from "../constants/api";

export const Auth = {
    login: async (body) => {
        try {
            const res = await fetch(`${BASE_URL}/users/login`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
    register: async (body) => {
        try {
            const res = await fetch(`${BASE_URL}/users`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
    funnelRegister: async (body) => {
        try {
            const res = await fetch(`${BASE_URL}/users/new-flow`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
    getUserFromToken: async (token) => {
        try {
            const res = await fetch(`${WORKER_URL}/users`, {
                headers: {
                    "x-auth-token": token
                },
                method: "GET"
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
    requestPasswordReset: async (body) => {
        try {
            const res = await fetch(`${WORKER_URL}/request-password-reset`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
    resetPassword: async (body) => {
        try {
            const res = await fetch(`${WORKER_URL}/reset-password`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    }
};