import {authToken} from "../constants/api";

export const SearchClient = {
    locations: async () => {
        try {
            const res = await fetch("https://user.therentfriend.nl/cities.json", {
                method: "GET",
            });

            return await res.json()
        } catch (e) {
            return {};
        }
    },
    add: async (body) => {
        try {
            const res = await fetch("https://trf.kouhai.workers.dev/searches", {
                method: "POST",
                headers: {
                    "x-auth-token": authToken
                },
                body: JSON.stringify(body)
            });

            return await res.json()
        } catch (e) {
            return null;
        }
    },
    update: async (body) => {
        try {
            const res = await fetch("https://trf.kouhai.workers.dev/searches", {
                method: "PUT",
                headers: {
                    "x-auth-token": authToken
                },
                body: JSON.stringify(body)
            });

            return await res.json()
        } catch (e) {
            return null;
        }
    },
    delete: async (body) => {
        try {
            const res = await fetch("https://trf.kouhai.workers.dev/searches", {
                method: "DELETE",
                headers: {
                    "x-auth-token": authToken
                },
                body: JSON.stringify(body)
            });

            return await res.json()
        } catch (e) {
            return null;
        }
    }
};