import {observer} from "mobx-react-lite";
import Faq from "../../components/faq";

function FAQ() {
    return (
        <>
            <Faq questionNumbers={[1, 2, 3, 4, 5, 6, 7]} />
        </>
    );
}

export default observer(FAQ);