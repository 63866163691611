import {observer} from "mobx-react-lite";
import {BannerContainer, BannerText} from "./styles";
import {FormattedMessage} from "react-intl";

function Banner() {
    return (
        <BannerContainer>
            <BannerText id="banner-text-desktop">
                <FormattedMessage
                    id="banner-text"
                    values={{
                        break: <><br/><br/></>
                    }}
                />
            </BannerText>
            <BannerText id="banner-text-mobile">
                <FormattedMessage
                    id="banner-text-mobile"
                    values={{
                        break: <><br/><br/></>
                    }}
                />
            </BannerText>
        </BannerContainer>
    );
}

export default observer(Banner);