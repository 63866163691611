import {observer} from "mobx-react-lite";
import Menu from "../menu";
import {Outlet, useLocation} from "react-router-dom";
import {StyledLayout} from "./styles";
import Meta from "../meta";
import {useEffect} from "react";

function Layout() {
    return (
        <>
            <Meta />
            <Menu />
            <StyledLayout>
                <Outlet />
            </StyledLayout>
        </>
    );
}

export default observer(Layout);