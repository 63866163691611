import styled from "styled-components";

export const ManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ManagementBtn = styled.button`
    background: none;
    border: none;
    padding: 16px 0;
    font-size: 1.3rem;
    width: 250px;
    
    &:focus {
        outline: none;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;