import {observer} from "mobx-react-lite";
import {localeStore} from "../../stores";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ArticleClient} from "../../agent/article";
import {ArticleContainer, ArticleContent} from "./styles";

function Article() {
    const {articleKey} = useParams();
    const lan = localeStore.value;
    const [article, setArticle] = useState(null);

    useEffect(() => {
        ArticleClient.get(articleKey).then((result) => setArticle(result));
    }, []);

    if (article) {
        return (
            <ArticleContainer>
                <h1>{article[lan].title}</h1>
                <p className="article-summary">{article[lan].summary}</p>

                <ArticleContent dangerouslySetInnerHTML={{ __html: article[lan].html }} />
            </ArticleContainer>
        )
    }

    return (
        <></>
    );
}

export default observer(Article);