import {observer} from "mobx-react-lite";
import {AllReasons, Reason, ReasonsContainer, ReasonsSectionHeader} from "./styles";
import {FormattedMessage} from "react-intl";
import {REASON_ONE, REASON_THREE, REASON_TWO} from "../../constants/images";
import StartSearchDispleased from "../../components/startSearchDispleased";

function Reasons() {

    return (
        <ReasonsContainer>
            <ReasonsSectionHeader>
                <FormattedMessage id="reasons-section-header" />
            </ReasonsSectionHeader>

            <AllReasons>
                <Reason>
                    <img src={REASON_ONE} alt="save-hours"/>
                    <h3><FormattedMessage id="reasons-one-header"/></h3>
                    <p><FormattedMessage id="reasons-one-description" /></p>
                </Reason>
                <Reason>
                    <img src={REASON_TWO} alt="respond-first"/>
                    <h3><FormattedMessage id="reasons-two-header"/></h3>
                    <p><FormattedMessage id="reasons-two-description" /></p>
                </Reason>
                <Reason>
                    <img src={REASON_THREE} alt="reason-one-image"/>
                    <h3><FormattedMessage id="reasons-three-header"/></h3>
                    <p><FormattedMessage id="reasons-three-description" /></p>
                </Reason>
            </AllReasons>
            <StartSearchDispleased />
        </ReasonsContainer>
    );
}

export default observer(Reasons);