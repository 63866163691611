import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {useIntl} from "react-intl";

function Meta() {
    const intl = useIntl();
    const location = useLocation();

    const formatPathPrefix = (path) => {
        let prefix = path.replaceAll("/", "");
        if (prefix === "") prefix = "home";

        return prefix;
    };

    const renderDescription = (path) => {
        const prefix = formatPathPrefix(path);
        const key = `${prefix}-meta-description`;
        const message = intl.formatMessage({id: key});
        return key === message ? "The Rent Friend" : message;
    };

    const renderTitle = (path) => {
        let prefix = formatPathPrefix(path);
        const key = `${prefix}-meta-title`;
        const message = intl.formatMessage({id: key});
        return key === message ? "The Rent Friend" : message;
    };

    return (
        <Helmet>
            <title>{renderTitle(location.pathname)}</title>
            <meta name="description" content={renderDescription(location.pathname)}/>
        </Helmet>
    );
}

export default observer(Meta);