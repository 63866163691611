import {observer} from "mobx-react-lite";
import {ArticleCardContainer, ArticleImage, ArticleInfo, ArticleSummary, ArticleTitle} from "./styles";
import {localeStore} from "../../stores";
import {SITE_LOGO} from "../../constants/images";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

function ArticleCard({articleKey, article}) {
    const lan = localeStore.value;
    const articleImage = article.image && article.image !== "" ? article.image : SITE_LOGO;
    const navigate = useNavigate();
    
    const openArticle = useCallback(() => {
        navigate(`/articles/${articleKey}`);
    }, [articleKey, navigate]);
    
    return (
        <ArticleCardContainer onClick={openArticle}>
            <ArticleImage src={articleImage} draggable={false}/>

            <ArticleInfo>
                <ArticleTitle>
                    {article[lan].title}
                </ArticleTitle>
                <ArticleSummary>
                    {article[lan].summary}
                </ArticleSummary>
            </ArticleInfo>
        </ArticleCardContainer>
    );
}

export default observer(ArticleCard);