import styled from "styled-components";

export const QuestionContainer = styled.div`
    margin-bottom: 25px;
`;

export const QuestionText = styled.button`
    border: none;
    background: none;
    font-weight: 600;
    padding: 0;
    text-align: left;
    display: inline-block;
    
    color: ${props => props.open ? "#214697": "black"}
`;

export const QuestionAnswer = styled.p`
    padding: 32px 0 16px 0;
    font-size: 1.2rem;
`;