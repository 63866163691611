import {observer} from "mobx-react-lite";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import {PriceRangeLabel, PriceRangeValue, PriceSliderContainer, RangeContainer} from "./styles";

function PriceSlider({priceRange, handlePriceRangeChange}) {
    return (
        <>
            <PriceSliderContainer>
                <PriceRangeLabel>Max price</PriceRangeLabel>

                <RangeContainer>
                    <PriceRangeValue>€{priceRange[0]}</PriceRangeValue>
                    <RangeSlider
                        min={200}
                        max={4000}
                        step={50}
                        value={priceRange}
                        onInput={handlePriceRangeChange}
                        rangeSlideDisabled={false}
                    />
                    <PriceRangeValue>€{priceRange[1]}</PriceRangeValue>
                </RangeContainer>
            </PriceSliderContainer>
        </>
    );
}

export default observer(PriceSlider);