import {observer} from "mobx-react-lite";
import {
    CloseMenuBtn,
    DropDownOption,
    MenuAction, MenuActionDropdown,
    MenuActions,
    MenuActionText,
    MenuContainer,
    MenuItem,
    MenuItems,
    MenuItemsList,
    MenuLogo, MenuToggle
} from "./styles";
import {MENU, SITE_LOGO} from "../../constants/images";
import {useState} from "react";
import {LANGUAGES} from "../../constants/countries";
import {FormattedMessage} from "react-intl";
import {localeStore, userStore} from "../../stores";
import {Link, useNavigate} from "react-router-dom";
import UserIcon from "../icons/user";
import {log} from "../../constants/firebase";
import LogoutIcon from "../icons/logout";

function Menu() {
    const navigate = useNavigate();
    const [languageOpen, setLanguageOpen] = useState(false);
    const selectedLanguage = LANGUAGES.find(l => l.value === localeStore.value);
    const [menuOpen, setMenuOpen] = useState(false);

    const onLanguageClick = (language) => {
        localeStore.setValue(language);
    };

    const logOut = (e) => {
        e.preventDefault();
        userStore.logout();
        navigate("/home");
    };

    const onMenuClick = (e) => {
        setMenuOpen(!(e.target.nodeName === "A"));
    };

    return (
        <MenuContainer>
            <MenuToggle src={MENU} alt="menu-toggle" onClick={() => setMenuOpen(true)}>
                <line y1="2.5" x2="43" y2="2.5" stroke="#214697" strokeWidth="5"/>
                <line y1="22.5" x2="43" y2="22.5" stroke="#214697" strokeWidth="5"/>
            </MenuToggle>
            <Link to="/home"><MenuLogo src={SITE_LOGO} alt="site-logo"/></Link>

            <MenuItems open={menuOpen}>
                <CloseMenuBtn onClick={() => setMenuOpen(false)}>╳</CloseMenuBtn>
                <MenuItemsList onClick={onMenuClick}>
                    <MenuItem to="/subscriptions">
                        <FormattedMessage id="nav-prices"/>
                    </MenuItem>
                    <MenuItem to="/articles">
                        <FormattedMessage id="articles"/>
                    </MenuItem>
                    <MenuItem to="/faq">FAQ</MenuItem>
                    <MenuItem to="/contact">Contact</MenuItem>

                    {!userStore.authenticated && (
                        <>
                            <MenuItem
                                onClick={() => log("sign_up", {event_name: "from_menu"})}
                                className="auth-action"
                                to="/register"
                            >
                                <FormattedMessage id="auth-nav-register"/>
                            </MenuItem>
                            <MenuItem className="auth-action" to="/login">
                                <FormattedMessage id="auth-nav-login"/>
                            </MenuItem>
                        </>
                    )}
                    {userStore.authenticated && (
                        <>
                            <MenuItem className="auth-action" to="/profile">
                                <FormattedMessage id="auth-nav-profile"/>
                            </MenuItem>
                            <MenuItem className="auth-action" onClick={logOut}>
                                <FormattedMessage id="auth-nav-log-out"/>
                            </MenuItem>
                        </>
                    )}
                </MenuItemsList>
            </MenuItems>

            <MenuActions>
                {!userStore.authenticated && (
                    <>
                        <MenuAction
                            className="auth-action"
                            onClick={() => {
                                log("sign_up", {event_name: "from_menu"});
                                navigate("/register")
                            }}
                        >
                            <UserIcon/>
                            <MenuActionText><FormattedMessage id="auth-nav-register"/></MenuActionText>
                        </MenuAction>
                        <MenuAction className="auth-action" onClick={() => navigate("/login")}>
                            <UserIcon/>
                            <MenuActionText><FormattedMessage id="auth-nav-login"/></MenuActionText>
                        </MenuAction>
                    </>
                )}
                {userStore.authenticated && (
                    <>
                        <MenuAction className="auth-action" onClick={logOut}>
                            <LogoutIcon/>
                            <MenuActionText><FormattedMessage id="auth-nav-log-out"/></MenuActionText>
                        </MenuAction>
                        <MenuAction className="auth-action" onClick={() => navigate("/profile")}>
                            <UserIcon/>
                            <MenuActionText><FormattedMessage id="auth-nav-profile"/></MenuActionText>
                        </MenuAction>
                    </>
                )}
                <MenuAction onClick={() => setLanguageOpen(!languageOpen)}>
                    <img src={selectedLanguage.icon} alt="current-language"/>
                    {languageOpen && (
                        <MenuActionDropdown>
                            {
                                LANGUAGES.map(language => (
                                    <DropDownOption
                                        onClick={() => onLanguageClick(language.value)}
                                        key={`language-${language.value}`}
                                    >
                                        <img src={language.icon} alt={language.value}/>
                                        <p>{language.label}</p>
                                    </DropDownOption>
                                ))
                            }
                        </MenuActionDropdown>
                    )}
                </MenuAction>
            </MenuActions>
        </MenuContainer>
    );
}

export default observer(Menu);