import styled from "styled-components";

export const ArticleCardContainer = styled.div`
    width: calc(100% / 3 - 20px);
    display: flex;
    flex-direction: column;
    cursor: pointer;

    @media (max-width: 1250px) {
        width: calc(100% / 2 - 20px);
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    &:hover {
        h4 {
            color: #214697;
        }
    }
`;

export const ArticleImage = styled.img`
    border: 1px solid lightgray;
`;

export const ArticleInfo = styled.div`
    margin: 8px 0;
    width: 100%;
`;

export const ArticleTitle = styled.h4`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const ArticleSummary = styled.p`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.2rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;