import {observer} from "mobx-react-lite";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {SITE_LOGO} from "../../constants/images";
import {
    AuthContainer, AuthForm,
    AuthFormContainer,
    AuthFormItem,
    AuthFormTitle,
    AuthSubmitButton
} from "../authentication/styles";
import CustomInput from "../../components/customInput";
import PasswordRequirements from "../../components/passwordRequirements";
import {IS_VALID_PASSWORD} from "../../constants/helpers";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {userStore} from "../../stores";
import {useNavigate, useSearchParams} from "react-router-dom";

function ResetPassword() {
    const [queryParam, _] = useSearchParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPasswordTip, setShowPasswordTip] = useState(false);

    const resetPassword = async () => {
        return await userStore.resetPassword(queryParam.get("token"), password);
    };

    const showResetPasswordMessage = (success, msgId) => {
        toast[success ? "success" : "error"](intl.formatMessage({id: msgId}), {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    };

    const submitForm = (e) => {
        e.preventDefault();

        if (password === confirmPassword && IS_VALID_PASSWORD(password)) {
            resetPassword().then(res => {
                if ("error" in res) showResetPasswordMessage(false, res?.error?.text?.replaceAll(" ", "-"));
                else if ("success" in res && res.success) showResetPasswordMessage(true, "reset-password-success");
            });
        } else {
            showResetPasswordMessage(false, "weak-password");
        }
    };

    useEffect(() => {
        if (!queryParam.get("token")) {
            navigate("/home");
        }
    }, []);

    return (
        <>
            <AuthContainer>
                <AuthFormContainer>
                    <img src={SITE_LOGO} width={100} alt="form-site-logo"/>
                    <AuthFormTitle>
                        {intl.formatMessage({id: "reset-password"}).replaceAll("?", "")}
                    </AuthFormTitle>

                    <AuthForm onSubmit={submitForm}>
                        <AuthFormItem>
                            <CustomInput
                                onFocus={() => setShowPasswordTip(true)}
                                onBlur={() => setShowPasswordTip(false)}
                                placeholder={intl.formatMessage({id: "auth-password-placeholder"})}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {showPasswordTip && (
                                <PasswordRequirements password={password} confirmPassword={confirmPassword}/>
                            )}
                        </AuthFormItem>
                        <AuthFormItem>
                            <CustomInput
                                onFocus={() => setShowPasswordTip(true)}
                                onBlur={() => setShowPasswordTip(false)}
                                placeholder={intl.formatMessage({id: "auth-confirm-password-placeholder"})}
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </AuthFormItem>
                        <AuthSubmitButton type="submit">
                            <FormattedMessage id="reset-password"/>
                        </AuthSubmitButton>
                    </AuthForm>
                </AuthFormContainer>
            </AuthContainer>
            <ToastContainer />
        </>
    );
}

export default observer(ResetPassword);