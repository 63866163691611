import styled from "styled-components";

export const SubscriptionCardContainer = styled.div`
    margin: 0 8px;
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    flex: 1;

    @media (max-width: 768px) {
        width: 100%;
        margin: 8px 0;
        display: none;
    }
`;

export const SubCardHeader = styled.div`
    background: #214697;
    width: 100%;
    color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SubDuration = styled.p`
    font-weight: 600;
`;

export const SubBody = styled.div`
    padding: ${props => props.isMedian ? "50px 25px" : "35px 20px"};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SubInfo = styled.div`
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    
    .sub-price {
        font-size: 5rem;
        
        .currency, .price-decimals {
            transform: translateY(10px);
            font-size: 2rem;
        }
    }
`;

export const SubPrice = styled.p`
    color: #333333;
    font-weight: 600;
    display: flex;
`;

export const PerMonth = styled.p`
    
`;

export const MaxSearches = styled.p`
    margin-top: 8px;
    text-align: center;
    margin-bottom: 40px;
`;

export const ActivateBtn = styled.button`
    color: white;
    background: #214697;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    font-weight: 600;
    
    &:hover {
        opacity: 0.8;
    }
`;