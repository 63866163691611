import {observer} from "mobx-react-lite";
import {AllArticles, ArticlesContainer, ArticlesTitle} from "./styles";
import {FormattedMessage} from "react-intl";
import {useEffect, useState} from "react";
import {ArticleClient} from "../../agent/article";
import ArticleCard from "../../components/articleCard";

function Articles() {
    const [articles, setArticles] = useState({});

    useEffect(() => {
        ArticleClient.all().then(res => setArticles(res));
    }, []);

    return (
        <ArticlesContainer>
            <ArticlesTitle>
                <FormattedMessage id="articles" />
            </ArticlesTitle>

            <AllArticles>
                {Object.keys(articles).map(key => (
                    <ArticleCard
                        articleKey={key}
                        article={articles[key]}
                    />
                ))}
            </AllArticles>
        </ArticlesContainer>
    );
}

export default observer(Articles);