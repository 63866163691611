import styled from "styled-components";
import {Link} from "react-router-dom";

export const MenuContainer = styled.header`
    min-width: 65%;
    padding: 24px;
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    height: 100px;
    
    ${Link}:nth-child(1){
        grid-column: 1 / 2;
    }

    @media (max-width: 1450px) {
        width: 100%;
        display: flex;
    }
`;

export const MenuToggle = styled.svg`
    width: 43px;
    height: 25px;
    margin-right: 20px;
    display: none;
    transform: scale(80%);

    @media (max-width: 1450px) {
        display: block;
    }
`;

export const MenuLogo = styled.img`
    width: 150px;
    cursor: pointer;

    @media (max-width: 1450px) {
        width: 130px;
    }
`;

export const MenuItems = styled.nav`
    grid-column: 2 / 3;
    justify-self: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1450px) {
        display: ${props => props.open ? "flex" : "none"};
        flex-flow: column;
        margin: 0;
        position: fixed;
        transform: translateX(-0%);
        left: 0;
        top: 0;
        background: white;
        height: 100%;
        width: 70vw;
        box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
        z-index: 1000;
    }
`;

export const CloseMenuBtn = styled.button`
    display: none;

    @media (max-width: 1450px) {
        display: block;
        width: fit-content;
        margin: 16px 16px 16px auto;
        background: none;
        border: none;
    }
`;

export const MenuItemsList = styled.ul`
    display: flex;
    padding: 0;
    align-items: center;
    
    .auth-action {
        display: none;
    }

    @media (max-width: 1450px) {
        flex-direction: column;
        align-items: baseline;
        
        .auth-action {
            display: block;
        }
    }
`;

export const MenuItem = styled(Link)`
    list-style-type: none;
    margin: 0 24px;
    cursor: pointer;
    color: #333333;
    opacity: 0.8;
    font-weight: 600;
    transition-duration: 150ms;
    text-decoration: none;
    
    &:hover {
        opacity: 1;
    }

    @media (max-width: 1450px) {
        padding: 24px 32px;
    }
`;

export const MenuActions = styled.div`
    display: flex;
    grid-column: 3 / 4;
    margin-left: auto;
    
    @media (max-width: 1450px) {
        margin-left: auto;
        
        .auth-action {
            display: none;
        }
    }
`;

export const MenuAction = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 8px;
    position: relative;
    background: none;
    border: none;
    
    img {
        width: 30px;
        height: 30px;
    }
`;

export const MenuActionText = styled.p`
    font-size: 1.2rem;
`;

export const MenuActionDropdown = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    background: white;
    z-index: 1000;
`;

export const DropDownOption = styled.div`
    padding: 8px;
    display: flex;
    align-items: center;
    
    img {
        margin-right: 8px;
    }
`;