import {observer} from "mobx-react-lite";
import {FormattedMessage} from "react-intl";
import {
    FunnelAuthContainer, FunnelAuthDropDownArrow, FunnelAuthHeader,
    FunnelAuthTitle,
    FunnelSearch,
    FunnelSearchLocation,
    FunnelSearchSpec,
    FunnelSearchSpecs
} from "./styles";
import {BED, DROPDOWN_ARROW, PRICE_TAG, RADIUS, RULER} from "../../constants/images";
import {useState} from "react";

function FunnelAuth() {
    const [open, setOpen] = useState(false);
    const funnelSearch = JSON.parse(localStorage.getItem("search"));

    return (
        <FunnelAuthContainer>
            <FunnelAuthHeader onClick={() => setOpen(!open)}>
                <FunnelAuthTitle><FormattedMessage id="your-search"/></FunnelAuthTitle>
                <FunnelAuthDropDownArrow src={DROPDOWN_ARROW} draggable={false} open={open} />
            </FunnelAuthHeader>

            <FunnelSearch open={open}>
                <FunnelSearchLocation>{funnelSearch.location}</FunnelSearchLocation>
                <FunnelSearchSpecs>
                    <FunnelSearchSpec>
                        <img src={RADIUS} alt="funnel-search-radius-icon" />
                        <p>{funnelSearch.radius} km</p>
                    </FunnelSearchSpec>
                    <FunnelSearchSpec>
                        <img src={PRICE_TAG} alt="funnel-search-radius-icon" />
                        <p>€{funnelSearch.rent_min} - €{funnelSearch.rent_max}</p>
                    </FunnelSearchSpec>
                    <FunnelSearchSpec>
                        <img src={RULER} alt="funnel-search-radius-icon" />
                        <p>≥ {funnelSearch.square_meters_min} m²</p>
                    </FunnelSearchSpec>
                    <FunnelSearchSpec>
                        <img src={BED} alt="funnel-search-radius-icon" />
                        <p>{funnelSearch.bedrooms_min}</p>
                    </FunnelSearchSpec>
                </FunnelSearchSpecs>
            </FunnelSearch>
        </FunnelAuthContainer>
    );
}

export default observer(FunnelAuth);