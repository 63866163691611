import {WORKER_URL} from "../constants/api";

export const ContactClient = {
    sendMessage: async (body) => {
        try {
            const res = await fetch(`${WORKER_URL}/contact`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return "success" in await res.json();
        } catch (e) {
            return null;
        }
    }
}