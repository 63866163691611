import {observer} from "mobx-react-lite";
import {SITE_LOGO} from "../../constants/images";
import {
    AuthContainer,
    AuthForm,
    AuthFormContainer,
    AuthFormItem,
    AuthFormTitle,
    AuthSubmitButton
} from "../authentication/styles";
import {FormattedMessage, useIntl} from "react-intl";
import CustomInput from "../../components/customInput";
import {useState} from "react";
import {IS_EMAIL_VALID} from "../../constants/helpers";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {userStore} from "../../stores";

function ForgotPassword() {
    const intl = useIntl();
    const [email, setEmail] = useState("");

    const afterForgotPasswordSubmit = (success, msg) => {
        toast[success ? "success" : "error"](msg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
        });
    };

    const submitForm = (e) => {
        e.preventDefault();

        if (IS_EMAIL_VALID(email)) {
            userStore.requestPasswordReset(email).then(res => {
                const success = "success" in res && res.success === true;
                afterForgotPasswordSubmit(
                    success,
                    success ? intl.formatMessage({id: "requested-reset-password"}) :
                        intl.formatMessage({id: res?.error?.text?.replaceAll(" ", "-") ?? "something-went-wrong"})
                );
            });
        } else {
            afterForgotPasswordSubmit(false, intl.formatMessage({id: "invalid-email"}));
        }
    };

    return (
        <>
            <AuthContainer>
                <AuthFormContainer>
                    <img src={SITE_LOGO} width={100} alt="form-site-logo" />
                    <AuthFormTitle>
                        {intl.formatMessage({id: "forgot-password"}).replaceAll("?", "")}
                    </AuthFormTitle>

                    <AuthForm onSubmit={submitForm}>
                        <AuthFormItem>
                            <CustomInput
                                placeholder={intl.formatMessage({id: "auth-email-placeholder"})}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </AuthFormItem>

                        <AuthSubmitButton type="submit">
                            <FormattedMessage id="reset-password" />
                        </AuthSubmitButton>
                    </AuthForm>
                </AuthFormContainer>
            </AuthContainer>
            <ToastContainer />
        </>
    );
}

export default observer(ForgotPassword);