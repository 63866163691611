import styled from "styled-components";
import {IntroButton} from "../intro/styles";

export const StartSearchFunnelContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 75px;
`;

export const StartFunnelSelectLabel = styled.p`
    font-size: 1.5rem;
    margin-bottom: 10px;
`;

export const StartFunnelBtn = styled(IntroButton)`
    margin-left: 8px;

    @media (max-width: 768px) {
        margin: 8px 0 0 0;
    }
`;

export const StartFunnelSelectContainer = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;